<template>
  <div class="box">
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="balanceDate" label="月份" align="center">
      </el-table-column>
      <el-table-column prop="isBalance" label="状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.isBalance == 0">未结存</span>
          <span v-else style="color: green">已结存</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="condense(scope.row.balanceDate, 1, scope.row.id)"
            :disabled="scope.row.isBalance == 1"
            >结存</el-button
          >
          <el-button
            type="text"
            @click="condense(scope.row.balanceDate, 0, scope.row.id)"
            :disabled="scope.row.isBalance == 0"
            >反结存</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @size-change="changePageSizes"
      @current-change="changeCurrentPages"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getBalance, setBalance } from "@/api/finance";
export default {
  data() {
    return {
      tableData: [],
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
    };
  },
  created() {
    this.getBalance();
  },
  methods: {
    changeCurrentPages(val) {
      this.searcForm.pageNum = val;
      this.getLists();
    },
    // 分页
    changePageSizes(val) {
      this.searchForm.pageSize = val;
      this.searchForm.pageNum = 1;
      this.getBalance();
    },
    async getBalance() {
      let params = this.searchForm;
      let res = await getBalance(params);
      if (res.code == 1) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
     condense(balanceDate, isBalance, id) {
      let title = "";
      if (isBalance == 1) {
        title = "结存";
      } else {
        title = "反结存";
      }
      this.$confirm(`是否对 ${balanceDate} 进行 ${title} 操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let params = {
            balanceDate,
            isBalance,
            id,
          };
          let res = await setBalance(params);
          if (res.code == 1) {
            this.getBalance();
            this.$message({
              type: "success",
              message: res.msg,
            });
          }else{
             this.$message.error({
            message: res.msg,
          });
          }
         
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${title}`,
          });
        });
    },
  },
};
</script>

<style scoped>
.pagination {
  width: 100%;
  text-align: center;
  /* margin-top: 10px; */
  position: absolute;
  bottom: -48px;
}
.box >>> .el-table th.el-table__cell > .cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-weight: bold;
  color: #000;
}
</style>